<template>
  <div>
    <el-row class="row">
      <custom-actions title="数据概况">
        <div class="device-row">
          <div class="com-col device-col">
            <span class="title">在线设备数</span>
            <animate-number class="num" from="0" :key="Math.random()*100" :to="overview.onLine" duration="1000" easing="easeOutQuad"></animate-number>
          </div>
          <div class="com-col device-col">
            <span class="title">离线设备数</span>
            <animate-number class="num" from="0" :key="Math.random()*200" :to="overview.offLine" duration="1000" easing="easeOutQuad"></animate-number>
          </div>
          <div class="com-col device-col">
            <span class="title">告警消息总数</span>
            <animate-number class="num" from="0" :key="Math.random()*300" :to="overview.msgNum" duration="1000" easing="easeOutQuad"></animate-number>
          </div>
          <div class="com-col device-col">
            <span class="title">今日告警消息数</span>
            <animate-number class="num" from="0" :key="Math.random()*400" :to="overview.msgDayNum" duration="1000" easing="easeOutQuad"></animate-number>
          </div>
        </div>
      </custom-actions>
    </el-row>
    <el-row class="row">
      <custom-actions title="总览">
        <template #header>
          <el-date-picker
            v-model="dateRange1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
          <el-button @click="loadDataCount" class="ml10" type="primary">查询</el-button>
        </template>

         <div class="overview-echart" ref="overviewEchart"></div>
      </custom-actions>
    </el-row>
    <el-row class="row" :gutter="15">
      <el-col :span="12">
        <custom-actions title="告警类型统计">
          <template #header>
            <el-date-picker
              v-model="dateRange2"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-button @click="loadMsgType" class="ml10" type="primary">查询</el-button>
          </template>
            <div class="warn-type-echart" ref="warnTypeEchart"></div>
        </custom-actions>
      </el-col>
      <el-col :span="12">
        <custom-actions title="日告警数量统计">
          <template #header>
            <el-date-picker
              v-model="dateRange3"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-button @click="loadMsgStation" class="ml10" type="primary">查询</el-button>
          </template>
          <div class="warn-echart" ref="warnEchart"></div>

        </custom-actions>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as Echarts from 'echarts'
export default {
  name: 'Index',
  data () {
    return {
      dateRange: [],
      overview: { // 数据概况
        total: '0',
        onLine: '0',
        offLine: '0',
        msgNum: '0',
        msgDayNum: '0'
      },
      msgStation: [], // 基站告警统计
      msgType: [], // 消息类型统计
      dataCount: [], // 用电量统计
      dateRange1: [], // 耗电量
      dateRange2: [], // 告警类型
      dateRange3: [] // 基站消息
    }
  },
  created () {
    const startTime = this.$dayjs().subtract(1, 'month').format('YYYY-MM-DD')
    const endTime = this.$dayjs().format('YYYY-MM-DD')

    const startTime3 = this.$dayjs().subtract(14, 'day').format('YYYY-MM-DD')
    const endTime3 = this.$dayjs().format('YYYY-MM-DD')
    this.dateRange1 = [startTime, endTime]
    this.dateRange2 = [startTime, endTime]
    this.dateRange3 = [startTime3, endTime3]

    this.loadData(startTime, endTime).then(res => {
      const { total, onLine, offLine, msgNum, msgDayNum } = res
      this.overview = { total, onLine, offLine, msgNum, msgDayNum }
      // this.renderDataCount(res.dataCount)
      this.renderMsgType(res.msgType)
      // this.renderMsgStation(res.msgStation)
    })
    this.loadMsgStation()
    this.loadDataCount()
  },
  mounted () {
    window.addEventListener('resize', this.changeResize)
  },

  methods: {
    changeResize () {
      this.overviewEchart && this.overviewEchart.resize()
      this.warnTypeEchart && this.warnTypeEchart.resize()
      this.warnEchart && this.warnEchart.resize()
    },

    // 告警饼图
    loadData (startTime, endTime) {
      return this.$axios.get(this.$apis.common.selectDeviceCount, {
        params: {
          startTime,
          endTime
        }
      }).then(res => {
        return res
      })
    },
    loadDataCount () {
      const [startTime, endTime] = this.dateRange1
      this.$axios.get(this.$apis.common.selectDeviceElectricity, {
        params: { startTime, endTime }
      }).then(res => {
        this.renderDataCount(res)
      })
      // this.loadData(startTime, endTime).then(res => {
      //   this.renderDataCount(res.dataCount)
      // })
    },
    renderDataCount (data) {
      console.log('加载日用电量图表')
      const dataDate = []
      const electricityNum = []
      const insideElectricityNum = []
      if (data && data.length > 0) {
        data.forEach(item => {
          dataDate.push(item.dataDate)
          electricityNum.push(item.electricityNum)
          insideElectricityNum.push(item.insideElectricityNum)
        })
      }

      this.$nextTick(() => {
        this.overviewEchart = Echarts.init(this.$refs.overviewEchart)
        const options = {
          title: {
            text: '设备基站用电量统计',
            textStyle: {
              fontSize: 16
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['基站外设备用电总量', '基站内设备用电总量']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: dataDate
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '基站外设备用电总量',
              type: 'line',
              stack: '总量',
              data: electricityNum,
              itemStyle: {
                normal: {
                  color: '#36CBCB'
                }
              }
            },
            {
              name: '基站内设备用电总量',
              type: 'line',
              stack: '总量',
              data: insideElectricityNum,
              itemStyle: {
                normal: {
                  color: '#5B8FF9'
                }
              }
            }

          ]
        }
        this.overviewEchart.clear()
        this.overviewEchart.setOption(options)
      })
    },
    loadMsgType () {
      const [startTime, endTime] = this.dateRange2
      this.loadData(startTime, endTime).then(res => {
        this.renderMsgType(res.msgType)
      })
    },
    renderMsgType (data) {
      console.log('加载告警类型统计')
      const dataArr = []
      if (data && data.length > 0) {
        data.forEach(item => {
          dataArr.push({
            value: item.msgNum,
            name: item.msgTypeName
          })
        })
      }

      this.$nextTick(() => {
        this.warnTypeEchart = Echarts.init(this.$refs.warnTypeEchart)
        const options = {
          title: {
            text: '告警类型统计',
            left: 'center',
            textStyle: {
              fontSize: 16
            }
          },
          tooltip: {
            show: true,
            // trigger: 'item'
            formatter: '{a} <br/>{b}: {c} ({d}%)'
          },

          legend: [
            {
              orient: 'vertical',

              left: 10,
              top: 20,
              icon: 'circle',
              itemWidth: 8,
              itemHeight: 8,
              tooltip: {
                show: true
                // trigger: 'item'
                // formatter: '{a} <br/>{b}: {c} ({d}%)'
              },

              textStyle: {
                fontSize: 14

              },

              data: dataArr
            }
          ],
          series: [
            {
              name: '告警类型',
              type: 'pie',
              radius: '50%',
              data: dataArr,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        }
        this.warnTypeEchart.clear()
        this.warnTypeEchart.setOption(options)
      })
    },
    loadMsgStation () {
      const [startTime, endTime] = this.dateRange3
      this.loadData(startTime, endTime).then(res => {
        this.renderMsgStation(res.msgStation)
      })
    },
    renderMsgStation (data) {
      console.log('加载基站告警类型统计')

      const msgNum = []
      const stationName = []
      if (data && data.length > 0) {
        data.forEach(item => {
          msgNum.push(item.msgNum)
          stationName.push(item.dataDate)
        })
      }
      this.$nextTick(() => {
        this.warnEchart = Echarts.init(this.$refs.warnEchart)
        const options = {
          title: {
            text: '日告警数量统计',
            textStyle: {
              fontSize: 16
            }
          },
          tooltip: {
            show: true
            // trigger: 'item'
          },
          xAxis: {
            data: stationName,

            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            }
          },
          yAxis: {
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#999'
              }
            }
          },

          series: [
            {
              type: 'bar',
              barMaxWidth: 15,
              showBackground: true,
              itemStyle: {
                normal: {
                  color: function (params) { // 展示正值的柱子，负数设为透明
                    return new Echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#FF9A22' // 0% 处的颜色
                    }, {
                      offset: 1,
                      color: '#FFD56E' // 100% 处的颜色
                    }], false)
                  }

                }
              },

              data: msgNum
            }

          ]

        }
        this.warnEchart.clear()
        this.warnEchart.setOption(options)
      })
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.changeResize)
    this.overviewEchart && this.overviewEchart.dispose()
    this.warnTypeEchart && this.warnTypeEchart.dispose()
    this.warnEchart && this.warnEchart.dispose()
  }
}
</script>

<style scoped lang="scss">
.row {
  margin-bottom: 15px;
}
.device-row {
  display: flex;

  .com-col {
    height: 150px;
    border-radius: 10px;
    margin-right: 20px;
    padding: 19px 26px;
    box-sizing: border-box;
    width: 375px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      width: 100%;
      text-align: left;
    }
    .num {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 72px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .device-col:nth-child(1) {
    background-color: #5bc674;
  }
  .device-col:nth-child(2) {
    background-color: #00b8f9;
  }
  .device-col:nth-child(3) {
    background-color: #ffa500;
  }
  .device-col:nth-child(4) {
    background-color: #ff5854;
  }
}
.overview-echart{
  width: 100%;
  height: 450px;
}
.warn-type-echart,.warn-echart{
  height: 500px;

}
</style>
